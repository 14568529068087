<template>
  <div class="ticket-history-wrapper">
    <Modal :fullScreen="isMobile"
           ref="modal"
           :showFooter="true"
           class="flex"
           width="800px"
           height="500px"
           v-if="showModal"
           @closeModal="closeModal"
           @closeModalOutside="closeModal('outside')">
      <template #header>
        <div class="flex-center">
          <span class="product-name">
             <LocalizedLabel translation="gameTitle" />
          </span>
          <i class="icon icon-close-a"
             @click="closeModal" />
        </div>
      </template>
      <template #body>
        <TicketsHistory
          :locale="config.locale"
          :productNameLabel="translations['gameTitle']"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Modal, TicketsHistory, eventBus } from '@nsftx/games-sdk-js';

export default {
  name: 'TicketsHistoryWrapper',
  components: {
    Modal,
    TicketsHistory,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'isMobile',
      'translations',
    ]),
  },
  methods: {
    closeModal(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
  },
  mounted() {
    eventBus.$on('openTicketHistory', () => {
      this.showModal = true;
    });
    eventBus.$on('TicketRebet', () => {
      this.showModal = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.ticket-history-wrapper {
  position: relative;

  ::v-deep {
    .modal-back {
      .modal {
        overflow: visible;
        background-color: var(--background-1);
        padding-bottom: 1em;

        .modal-header, .modal-body {
          color: var(--text-primary-1);
        }

        .modal-header {
          font-size: 16px;
        }
        .modal-body {
          font-size: 14px;
          overflow-y: auto;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
        }

        .modal-body::-webkit-scrollbar {
          display: none;  /* Safari and Chrome */
        }

        .input-container {
          height: 18px;
        }

        .ticket-details-modal {
          .modal {
            background-color: var(--card);
          }
        }
      }
    }
  }

  .flex-center {
    display: block;
    align-items: center;
    text-align: center;

    .icon {
      float: right;
      cursor: pointer;
    }

    .product-name {
      font-size: 24px;
      text-align: center;
    }
  }
}

@media(max-width: 640px) {
  .ticket-history-wrapper {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-name {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
</style>
