export default {
  getColorId(ball) {
    let colorId = '';

    if (ball) {
      colorId = (ball - 1) % 8;
    }

    return String(colorId);
  },
};
