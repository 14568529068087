<template>
  <div class="home"
       :class="[gameChannelType.toLowerCase(),
       {'non-betting-area': activeTerminalLayout !== 'Terminal' && gameChannelType !== 'Web'}]">
    <component :is="channelType" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    Web: () => import(/* webpackChunkName: "type.web" */'./Web'),
    Terminal: () => import(/* webpackChunkName: "type.terminal" */'./Terminal'),
    TerminalResults: () => import(/* webpackChunkName: "type.terminal" */'./TerminalResults'),
    TerminalTicketHistory: () => import(/* webpackChunkName: "type.terminal" */'./TerminalTicketHistory'),
    TicketCheck: () => import(/* webpackChunkName: "type.terminal" */'./TicketCheck'),
  },
  computed: {
    ...mapGetters([
      'gameChannelType',
      'activeTerminalLayout',
    ]),
    channelType() {
      if (this.activeTerminalLayout === 'Terminal') {
        return 'Terminal';
      }
      if (this.activeTerminalLayout === 'TerminalResults') {
        return 'TerminalResults';
      }
      if (this.activeTerminalLayout === 'TerminalTicketHistory') {
        return 'TerminalTicketHistory';
      }
      if (this.activeTerminalLayout === 'TicketCheck') {
        return 'TicketCheck';
      }
      return this.gameChannelType;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  &.non-betting-area {
    height: 100vh;
    background-color: var(--card);
  }
}
</style>
