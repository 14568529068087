export default {
  translations: (locale) => {
    switch (locale) {
      case 'hr':
      case 'bs':
      case 'cnr':
        return import(/* webpackChunkName: "i18.hr" */'./l6.hr');
      case 'cs':
        return import(/* webpackChunkName: "i18.cs" */'./l6.cs');
      case 'ro':
        return import(/* webpackChunkName: "i18.ro" */'./l6.ro');
      case 'sr-Latn':
        return import(/* webpackChunkName: "i18.sr-Latn" */'./l6.sr-Latn');
      case 'rs':
        return import(/* webpackChunkName: "i18.rs" */'./l6.rs');
      case 'de':
        return import(/* webpackChunkName: "i18.de" */'./l6.de');
      case 'sl':
        return import(/* webpackChunkName: "i18.sl" */'./l6.sl');
      case 'it':
        return import(/* webpackChunkName: "i18.it" */'./l6.it');
      case 'es':
        return import(/* webpackChunkName: "i18.es" */'./l6.es');
      case 'fr':
        return import(/* webpackChunkName: "i18.fr" */'./l6.fr');
      case 'tr':
        return import(/* webpackChunkName: "i18.tr" */'./l6.tr');
      case 'az':
        return import(/* webpackChunkName: "i18.az" */'./l6.az');
      default:
        return import(/* webpackChunkName: "i18.en" */'./l6.en');
    }
  },
};
