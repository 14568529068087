<template>
  <div class="draw-details-wrapper">
    <Tabs
      :tabs="enabledTabs"
      :activeTab="activeComponent"
      @change="updateTab"
    ></Tabs>
    <div class="draw-details-body">
      <component :is="activeComponent.component"></component>
    </div>
  </div>
</template>

<script>
import { each, filter, findIndex } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { Tabs, eventBus } from '@nsftx/games-sdk-js';
import Boost from './Boost';
import Statistics from './Statistics';
import Results from './Results';
import GameHelp from './GameHelp';

export default {
  name: 'DrawDetails',
  components: {
    Boost,
    Statistics,
    Results,
    Tabs,
    GameHelp,
  },
  data() {
    return {
      tabs: [],
      activeComponent: {},
      enabledTabs: [],
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'user/user',
      'isBoostActive',
      'isMobile',
      'config',
    ]),
    userProfile() {
      return this['user/user'];
    },
  },
  methods: {
    ...mapActions(['getResults', 'getStats']),
    updateTab(event) {
      const activeIndex = findIndex(this.enabledTabs, (tab) => event.id === tab.id);
      this.activeComponent = this.enabledTabs[activeIndex];
    },
    handleShowResults() {
      [, this.activeComponent] = this.enabledTabs;
    },
    handleShowStats() {
      [this.activeComponent] = this.enabledTabs;
    },
    handleShowHelp() {
      if (this.isMobile) {
        [, , this.activeComponent] = this.enabledTabs;
      }
    },
  },
  mounted() {
    eventBus.$on('ShowResults', this.handleShowResults);
    eventBus.$on('ShowStats', this.handleShowStats);
    eventBus.$on('ShowHelp', this.handleShowHelp);
    this.tabs = [
      {
        label: this.translations.general_activity,
        component: 'Boost',
        disabled: !this.isBoostActive || this.isMobile,
      },
      {
        label: this.translations.general_statistics,
        component: 'Statistics',
        disabled: false,
      },
      {
        label: this.translations.general_results,
        component: 'Results',
        disabled: false,
      },
      {
        label: this.translations.general_help,
        component: 'GameHelp',
        disabled: !this.config.ui.config.luckySixHelpPath || !this.isMobile,
      },
    ];

    this.enabledTabs = filter(this.tabs, (tab) => !tab.disabled);

    each(this.enabledTabs, (t, i) => {
      const tab = t;
      tab.id = i;
    });

    [this.activeComponent] = this.enabledTabs;
    this.getStats();
    this.getResults();
  },
  beforeDestroy() {
    eventBus.$off('ShowResults', this.handleShowResults);
    eventBus.$off('ShowStats', this.handleShowStats);
    eventBus.$off('ShowHelp', this.handleShowHelp);
  },
  watch: {
    'userProfile.profile.id': function reinitializeActiveComponent() {
      this.activeComponent = {};
      setTimeout(() => {
        [this.activeComponent] = this.enabledTabs;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .draw-details-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--card);

    .tabs {
      --tab-border-color: rgba(255, 255, 255, 0.05);
      border-bottom: 2px solid var(--tab-border-color);
      background-color: var(--card);
    }

    .draw-details-body {
      float: left;
      width: 100%;
      background-color: var(--card);
      text-align: center;
      min-height: 600px;
      height: calc(100% - 40px);
      overflow: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      ::v-deep {
        .helpWrapper {
          iframe {
            height: 100vh;
          }
        }
      }
    }
  }
</style>
