<template>
    <div class="app"
        id="app"
         :class="[theme, isCursorHidden]"
         ref="app">
      <router-view></router-view>
      <NewFeatures />
      <TicketsHistory v-if="userProfile.profile.id" />
      <Modal v-if="ticketCanceled"
             showFooter
             class="cancel-ticket-confirm"
             @closeModal="closeCancelTicketModal"
             @closeModalOutside="closeCancelTicketModal('outside')"
             ref="modal">
        <h3 slot="header">
          {{ translations.general_info }}
        </h3>
        <div slot="body">
          {{ ticketCanceledMessage }}
          <div class="modal-actions">
            <button class="button"
                    @click="closeCancelTicketModal">
              {{ translations.ok }}
            </button>
          </div>
        </div>
        <div slot="footer">
          <button class="button"
                  @click="closeCancelTicketModal">
            {{ translations.ok }}
          </button>
        </div>
      </Modal>
      <Modal
        v-if="showBonusModal"
        class="free-bet-modal"
        width="344px"
        height="348px"
        showFooter
        @closeModal="claimBonuses()"
        @closeModalOutside="claimBonuses('outside')"
        ref="modal">
        <div slot="body">
          <div class="body-message-box">
            <div class="title">{{ translations.general_free_bet }}</div>
            <div class="subtitle">{{ translations.general_congratulation.toUpperCase() }}</div>
            <span class="subtitle-2">{{ translations.general_claim_ticket }}</span>
          </div>
          <div class="action">
            <button class="button" @click="claimBonuses">
              {{ translations.general_claim.toUpperCase() }}
            </button>
          </div>
        </div>
      </Modal>
    </div>
</template>

<script>
import Vue from 'vue';
import {
  has,
  merge,
  isArray,
  clone,
  isNil,
  startCase,
  assign,
  each,
} from 'lodash';
import {
  applyPalette,
  gtag,
  Modal,
  eventBus,
} from '@nsftx/games-sdk-js';
import { mapGetters, mapActions } from 'vuex';
import { BusService } from '@nsftx/games-bus';
import store from '@/store';
import integrator from '@/utility/integrator';
import webViewIntegration from '@/utility/webViewIntegration';
import NoSleep from 'nosleep.js';
import NewFeatures from './components/NewFeatures';
import TicketsHistory from './components/TicketsHistory';

export default {
  name: 'App',
  components: {
    NewFeatures,
    TicketsHistory,
    Modal,
  },
  data() {
    return {
      isLoggedIn: false,
      check: null,
      checkTimeout: 10000,
      payinConnection: null,
      payinTimeout: 10000,
      ticketCanceled: false,
      canceledTicket: {},
      bonuses: [],
      stopBettingTimeout: null,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'user/user',
      'translations',
      'gameState',
      'localizedTranslations',
      'roundId',
      'jackpotState',
      'isIsonis',
      'showBonusModal',
      'isTouchDevice',
      'isMobile',
      'isTablet',
      'isDesktop',
      'isBettingDisabled',
      'selectedBet',
      'isTerminal',
      'isTerminalMouseCursorEnabled',
      'activeTerminalLayout',
      'terminalUserData',
      'userIsOperator',
      'firstBallColor',
      'ticketDetailsData',
      'ticketToRebet',
      'ajsDisabled',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    ...mapGetters('betslip', [
      'future',
      'isBetslipOpened',
      'payment',
      'isFuturePerBetActive',
    ]),
    ...mapGetters('gamesBetslip', [
      'ticket',
      'totalPayment',
    ]),
    userProfile() {
      return this['user/user'];
    },
    getTicketDetailsAuth() {
      return btoa(`${this.config.account.uuid}:${this.terminalUserData.auth.password}`);
    },
    isAndroidPlatform() {
      return webViewIntegration.isActive() && has(window, 'WebAppListener');
    },
    ticketCanceledMessage() {
      return this.translations.general_ticket + ' ' + this.canceledTicket.id + ' '
        + `${this.translations.general_successfully_cancelled}!`;
    },
    theme() {
      return this.config.ui.config.theme;
    },
    isCursorHidden() {
      if (this.isTerminal && !this.isTerminalMouseCursorEnabled) {
        return 'hide-cursor';
      }
      return '';
    },
    isStandAlone() {
      return this.config.ui.config.mode === 'standalone';
    },
    isGravityGateway() {
      return this.config.messageProtocol.name === 'gravityGateway';
    },
    getTicketHistoryAuthHeaders() {
      const headers = {
        Authorization: `Bearer ${this.terminalUserData.auth.token}`,
        'HTTP-X-NAB-BETSHOP-ID': this.config.betshop.id,
        'HTTP-X-NAB-COMPANY-ID': this.config.companyData.id,
        'HTTP-X-NAB-DEVICE-UUID': this.config.device.deviceUuid,
        'HTTP-X-NAB-DP': 'AdminTerminal',
        'SEVEN-LOCALE': this.config.locale,
      };
      return headers;
    },
  },
  methods: {
    ...mapActions([
      'setDeviceType',
      'setUserData',
      'setUserBalance',
      'getResults',
      'getStats',
      'setGameState',
      'updateRound',
      'updateGameState',
      'setIsMobile',
      'setIsBetslipOpen',
      'setJackpotState',
      'toggleBonusModal',
      'getUserBonuses',
      'setApplicationMode',
      'setIsTouchDevice',
      'setCountdownData',
      'setTicketHistoryTerminal',
      'setTicketDetailsData',
      'setLoaded',
      'setActiveComponent',
      'setTicketToRebet',
      'setUserIsOperator',
    ]),
    ...mapActions({
      clearBetslip: 'gamesBetslip/clearBetslip',
      setIsPayinButtonDisabled: 'gamesBetslip/setIsPayinButtonDisabled',
      setNotification: 'notifications/setNotification',
      disableFreeBetButton: 'betslip/disableFreeBetButton',
      getLastTickets: 'gamesBetslip/getLastTickets',
      getTicketsHistory: 'betslip/getTicketsHistory',
      setPlayerTickets: 'betslip/setPlayerTickets',
      setUserData: 'user/setUserData',
      setUserBonuses: 'user/setUserBonuses',
      setIsQuickpayEnabled: 'gamesBetslip/setIsQuickpayEnabled',
      setFutureRounds: 'gamesBetslip/setFutureRounds',
      updateBet: 'gamesBetslip/updateBet',
      clearSelection: 'clearSelection',
      getUserBalance: 'user/getUserBalance',
      setUserBalance: 'user/setUserBalance',
    }),
    preInit() {
      const isMob = this.isMobile;
      const { isTablet } = this;
      const isTouchDevice = this.isMobile || this.isTablet;
      const device = isMob ? 'mobile' : isTablet ? 'tablet' : 'desktop';
      this.setHTMLLangAttribute();
      this.setIsMobile(isMob);
      this.setIsTouchDevice(isTouchDevice);
      this.setDeviceType(device);
      this.setupGtag(device);
      this.setPageTitle();
    },
    init() {
      this.sendLoadedEvent();
      this.setTheme();
      this.setAppMode();
      if (this.config.platformName !== 'seven') {
        this.thirdPartyPlatformSubscribe();
      }
    },
    sendLoadedEvent() {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Slave.Loaded');
      }
      this.busService.sendMessage({
        action: 'Slave.Loaded',
      }, true);
    },
    setHTMLLangAttribute() {
      document.querySelector('html').setAttribute('lang', this.config.locale);
    },
    setTheme() {
      const rootElement = document.documentElement;
      const { config } = this.config.ui;
      const baseColor = this.config.ui[config.baseColor];
      const theme = this.config.ui[config.theme];
      applyPalette.set(merge(baseColor, theme), rootElement);
    },
    setAppMode() {
      const mode = this.config?.ui?.config?.mode || 'standalone';
      this.setApplicationMode(mode);
    },
    thirdPartyPlatformSubscribe() {
      if (this.config.user && this.config.user.id) {
        const queryParams = this.$route.query;
        this.authUser(this.config.user);
        this.setUserBalance(this.config.user.balance);
        if (!this.isTerminal) {
          this.getLastTickets();
        }
        this.busService.addChannel('DeliveryPlatform', this.config.platformConfig.clientPlatform);
        this.busService.addChannel('Player', this.config.user.username || this.config.user.id);
        if (queryParams.casino) {
          this.busService.addChannel('Balance', `${queryParams.casino}-${this.config.user.username}`);
        }
      }
    },
    closeCancelTicketModal() {
      this.ticketCanceled = false;
    },
    formatTicketBets() {
      const bets = this.ticket.length ? this.ticket : [this.selectedBet];
      return bets.map((bet) => ({
        payin: Number(bet.stake) || Number(this.totalPayment),
        type: isArray(bet.cleanOutcome) && bet.cleanOutcome.length >= 6
          ? bet.cleanOutcome.length - 6
          : (!isNil(bet.type)
            ? bet.type
            : bet.id),
        value: !isNil(bet.outcomeIndex)
          ? String(bet.outcomeIndex)
          : (isArray(bet.cleanOutcome)
            ? bet.cleanOutcome.join(',')
            : bet.outcome.split(' ').join(',')),
        numEvents: bet.numEvents || 1,
      }));
    },
    setupGtag(device) {
      if (this.config.ui.config.gtm && this.config.ui.config.gtm[device]) {
        const { code, auth, preview } = this?.config?.ui?.config?.gtm[device];
        gtag.setup(code, auth, preview);
        return;
      }
      gtag.setup();
    },
    clearCheckTimeout() {
      clearTimeout(this.check);
    },
    clearStopBettingTimeout() {
      clearTimeout(this.stopBettingTimeout);
      this.stopBettingTimeout = null;
    },
    checkTicket(requestUuid) {
      this.check = setTimeout(() => {
        store.dispatch('betslip/ticketCheck', requestUuid).then((response) => {
          if (response.data.status.value.toUpperCase() !== 'PENDING') {
            const message = {
              message: this.translations.general_ticket_confirmed,
              status: 'positive',
              notificationTimeout: 1,
            };
            store.dispatch('notifications/clearNotification').then(() => {
              store.dispatch('notifications/setNotification', message);
            });
            if (this.isTerminal) {
              eventBus.$emit('UpdateTicketOnError', requestUuid);
            }
            if (!this.isTerminal) {
              this.getLastTickets();
            }
            this.setIsPayinButtonDisabled(false);
            this.disableFreeBetButton(false);
            this.clearBetslip();
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('bettingDisable', false);
            if (this.isBetslipOpened) {
              store.dispatch('betslip/showBetslip');
            }
          }
          this.clearCheckTimeout();
        }).catch(() => {
          if (this.isTerminal) {
            eventBus.$emit('UpdateTicketOnError', requestUuid);
          }
          store.dispatch('bettingDisable', false);
          store.dispatch('gamesBetslip/setIsPayinInProgress', false);
          store.dispatch('notifications/clearNotification');
          this.setIsPayinButtonDisabled(false);
          this.disableFreeBetButton(false);
        });
      }, this.checkTimeout);
    },
    updateTicketRound() {
      this.ticket.forEach((bet) => {
        this.updateBet({
          ...bet,
          roundNumber: this.roundId,
        });
      });
    },
    getPrintTemplate() {
      let template = '';
      const messages = this.translations;
      const { currency } = this.config;
      const bets = this.ticket.length ? this.ticket : [this.selectedBet];
      template += 'LuckySix;';
      template += "{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }};";
      template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
      template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;

      return (
        template + bets.map((bet) => {
          const betOutcome = isArray(bet.outcome) ? bet.outcome.join(',') : bet.outcome;
          const betMarket = !bet.color ? `${bet.market} ${bet.category ? bet.category : ''}` : bet.category;
          let body = '';
          let round = '';

          for (let i = 0; i < bet.numEvents; i += 1) {
            round = `${messages.general_round} : {{bets[0].eventId + ${i}}}`;
            body += `${round} `;
            body += `${betOutcome} `;
            body += `${betMarket} `;
          }
          return body;
        }).join('; ') + '{{ id }}'
      );
    },
    updateTicketWithRequestUuid(requestUuid) {
      this.$busService.sendMessage({
        action: 'Tickets.Update',
        enforceEvent: true,
        data: {
          action: 'Add',
          showMessage: false,
          ticketData: {
            product: 'LuckySix',
            requestUuid,
          },
        },
      }, true);
    },
    authUser(auth) {
      store.dispatch('user/updateUser', {
        auth: {
          token: auth.token,
          tpToken: auth.tpToken,
        },
        profile: auth.user || auth,
      }, { root: true });
      this.isLoggedIn = !!auth?.user?.logged || auth.id;
      if (this.isLoggedIn) {
        if (!this.isTerminal) {
          this.getLastTickets();
          this.getUserBonuses();
        }
      }
    },
    claimBonuses() {
      this.setUserBonuses(this.bonuses);
      this.toggleBonusModal(false);
    },
    checkForRedirect() {
      const { clientplatform } = this.config;
      const redirectUrl = this.config.company.redirect.url;
      if (clientplatform && clientplatform === 'download') {
        window.location.replace(redirectUrl);
      }
    },
    getBetTypeLabel(type) {
      const types = {
        0: 'STANDARD',
        3: 'general_ticket_system',
        5: 'games_first_ball_color',
        6: 'luckysix_preballs_sum_low_high',
        7: 'games_first_ball_odd_even',
        8: 'luckysix_first_ball_low_high',
        10: 'general_preball',
        11: 'luckysix_more_preballs',
      };
      return types[type];
    },
    getBetNameLabel(bet) {
      if (bet.type === 5) {
        const colors = [];
        let colorsString = '';
        let colorIndex = '';
        each([0, 1, 2, 3, 4, 5, 6, 7, 8], (n) => {
          if (parseInt(bet.value, 10) & 2 ** n) {
            colorIndex = (n === 0) ? 7 : n - 1;
            colors.push(this.firstBallColor.outcomes[colorIndex]);
            colorsString = colors.join(' ');
          }
        });
        return colorsString;
      }
      if (bet.type === 10 || bet.type === 1 || bet.type === 2 || bet.type === 3 || bet.type === 4) {
        return bet.value;
      }
      const betTypes = {
        6: {
          0: 'general_under',
          1: 'general_over',
        },
        7: {
          0: 'general_even',
          1: 'general_odd',
        },
        8: {
          0: 'general_under',
          1: 'general_over',
        },
        11: {
          0: 'general_even',
          1: 'general_odd',
        },
      };
      return betTypes[bet.type][bet.value];
    },
    initBusService() {
      this.busService = new BusService({
        environment: this.config.environment,
        platform: this.config.platformName,
        productName: this.config.productName,
        productId: this.config.productId,
        tenantId: this.config.tenantId,
        locale: this.config.locale,
        applicationName: this.config.applicationName,
        //  subChannels: [],
        useCommonEventName: true,
        adapters: [
          { name: 'GcmAdapter' },
        ],
        clientMessageProtocol: this.config.messageProtocol,
        bounceToFrames: [
          { frameId: 'luckysixVisualization' },
        ],
      });
      this.busService.start();
      Vue.prototype.$busService = this.busService;
      (async () => {
        if (this.isTerminal) {
          this.busService.addChannel('Device', this.config.device.uuid);
        }
      })();
    },
    ticketPayin() {
      this.disableFreeBetButton(true);
      store.dispatch('gamesBetslip/setIsPayinInProgress', true);
      store.dispatch('bettingDisable', true);
      store.dispatch('gamesBetslip/payIn', {
        payload: this.formatTicketBets,
        additionalInfo: this.isIsonis ? {
          printTemplate: this.getPrintTemplate(),
          timezone: {
            offset: parseInt(this.userProfile.profile.profile.timezoneOffset, 10),
          },
        } : null,
      })
        .then((response) => {
          this.checkTicket(response.data.requestUuid);
          const message = {
            message: this.translations.general_ticket_resolving,
            status: 'neutral',
          };
          this.setNotification(message);
        })
        .catch((error) => {
          if (error) {
            this.payinConnection = setTimeout(() => {
              const message = {
                message: this.translations.general_ticket_error,
                status: 'negative',
              };
              this.setNotification(message);
              store.dispatch('gamesBetslip/setIsPayinInProgress', false);
              store.dispatch('bettingDisable', false);
              clearTimeout(this.payinConnection);
            }, this.payinTimeout);
          }
          if (error.response) {
            const errorData = error.response.data;
            const message = {
              message: errorData.message,
              status: 'negative',
            };
            this.setNotification(message);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('bettingDisable', false);
          }
          this.disableFreeBetButton(false);
          return error;
        });
    },
    ticketPayinTerminal() {
      store.dispatch('gamesBetslip/setIsPayinInProgress', true);
      store.dispatch('bettingDisable', true);
      store.dispatch('gamesBetslip/payIn', {
        payload: this.formatTicketBets,
        additionalInfo: this.isIsonis ? {
          printTemplate: this.getPrintTemplate(),
          timezone: {
            offset: parseInt(this.userProfile.profile.profile.timezoneOffset, 10),
          },
        } : null,
      })
        .then((response) => {
          const config = {
            ticketGroup: this.config.productName,
          };
          const formatedPayload = response;
          formatedPayload.ticket.stake = response.ticket.payin;
          formatedPayload.ticket.config = config;
          if (formatedPayload.ticket.payin > this['user/user'].balance) {
            this.busService.sendMessage({
              action: 'Dialog.Show',
              data: {
                message: this.translations['notifications.insufficient_funds'],
                type: 'warning',
                delay: 3000,
              },
            }, true);
            return;
          }
          this.busService.clientAdapter.handler.emitAsync({
            action: 'Tickets.Pay',
            data: formatedPayload,
            async: true,
          }).then((response2) => {
            this.checkTicket(response2.ticket.requestUuid);
          });
          //  this.setNotification(message);
        })
        .catch((error) => {
          if (error) {
            this.payinConnection = setTimeout(() => {
              const message = {
                message: this.translations.general_ticket_error,
                status: 'negative',
              };
              this.setNotification(message);
              store.dispatch('gamesBetslip/setIsPayinInProgress', false);
              store.dispatch('bettingDisable', false);
              clearTimeout(this.payinConnection);
            }, this.payinTimeout);
          }
          if (error.response) {
            const errorData = error.response.data;
            const message = {
              message: errorData.message,
              status: 'negative',
            };
            this.setNotification(message);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('bettingDisable', false);
          }
          this.disableFreeBetButton(false);
          return error;
        });
    },
    updatePlayerChannel(playerId) {
      if (playerId) {
        this.busService.addChannel('Player', playerId);
      } else {
        this.busService.removeChannel('Player', this.userProfile.profile.id);
      }
    },
    disableBetting(notification) {
      store.dispatch('bettingDisable', true);
      this.setIsPayinButtonDisabled(this.isBettingDisabled);
      store.dispatch('notifications/clearNotification')
        .then(() => {
          store.dispatch('notifications/setNotification', notification);
        });
      this.disableFreeBetButton(true);
    },
    setPageTitle() {
      const { productFqn, applicationName } = this.config;
      document.title = `${startCase(productFqn)}: ${applicationName}`;
    },
    stopBettingTimout() {
      this.stopBettingTimeout = setTimeout(() => {
        store.dispatch('bettingDisable', false);
        this.setIsPayinButtonDisabled(this.isBettingDisabled);
      }, 20000);
    },
  },
  beforeMount() {
    this.preInit();
    this.initBusService();
    if (window.innerWidth <= 960 && window.innerWidth > 640) {
      this.setIsBetslipOpen(false);
    }
    (async () => {
      if (!this.ajsDisabled) {
        await this.setJackpotState();
        if (this.jackpotState) {
          this.busService.addChannel('Jackpot', this.jackpotState.jackpotId);
        }
      }
    })();
  },
  mounted() {
    if (this.isMobile) {
      const noSleep = new NoSleep();
      let wakeLockEnabled = false;
      document.addEventListener('click', function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        if (!wakeLockEnabled) {
          noSleep.enable();
          wakeLockEnabled = true;
        } else {
          noSleep.disable();
          wakeLockEnabled = false;
        }
      }, false);
    }
    this.init();
    window.addEventListener('Bus:Message', (event) => {
      const { eventName } = event.detail;
      const eventDetail = event.detail;
      const notification = {
        message: '',
        status: '',
        notificationTimeout: 1,
      };
      switch (eventName) {
        case 'SetState':
          if (this.isTerminal) {
            if (event.detail.type === 'ball') {
              this.setCountdownData(null);
            }
            if (eventDetail.type === 'countdown' || eventDetail.type === 'bettingDisabled') {
              const countdownData = {
                delay: eventDetail.delay,
                sentTime: eventDetail.sentTime,
                time: eventDetail.time,
              };
              this.setCountdownData(countdownData);
            }
          }
          if (eventDetail.type) {
            this.setGameState(eventDetail);
          }
          if (eventDetail.type === 'countdown') {
            this.updateRound(eventDetail.eventId);
          } else if (eventDetail.type === 'ball' || eventDetail.type === 'results') {
            this.updateRound(eventDetail.eventId + 1);
          } else if (eventDetail.type === 'bettingDisabled') {
            notification.message = this.translations.general_betting_disabled_warning;
            notification.status = 'negative';
            this.disableBetting(notification);
          }
          break;
        case 'StartEvent':
          setTimeout(() => {
            if (this.isTerminal && this.ticketDetailsData) {
              if (this.ticketDetailsData.status.id !== '5') {
                fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
                  headers: {
                    Authorization: `Basic ${this.getTicketDetailsAuth}`,
                  },
                })
                  .then((response) => response.text())
                  .then((text) => this.setTicketDetailsData(JSON.parse(text)));
              }
            }
          }, 1000);
          this.setCountdownData(null);
          this.clearStopBettingTimeout();
          this.setGameState(eventDetail);
          this.updateRound(eventDetail.eventId + 1);
          this.updateTicketRound();
          this.disableFreeBetButton(false);
          store.dispatch('bettingDisable', false);
          this.setIsPayinButtonDisabled(this.isBettingDisabled);
          if (this.isLoggedIn && !this.isTerminal) {
            setTimeout(() => {
              this.getLastTickets();
            }, 500);
          }
          break;
        case 'DrawResult': {
          const currentBallEventId = event.detail.eventId;
          const currentBallId = event.detail.id;
          const currentBalls = clone(this.gameState.balls);
          const isCurrentBallMissing = currentBalls.findIndex((n) => n.id === currentBallId) < 0;

          if (this.stopBettingTimeout) this.clearStopBettingTimeout();

          if (isCurrentBallMissing) {
            currentBalls.push({
              ball: event.detail.ball,
              id: event.detail.id,
              eventId: currentBallEventId,
            });

            this.updateGameState({
              ball: event.detail.ball,
              currentBallId,
              balls: currentBalls,
              eventId: currentBallEventId,
              eventName: event.detail.eventName,
              sentTime: event.detail.sentTime,
            });
          }
          break;
        }
        case 'StopBetting':
          notification.message = this.translations.general_betting_disabled_warning;
          notification.status = 'negative';
          this.disableBetting(notification);
          this.stopBettingTimout();
          break;
        case 'SetResults':
          setTimeout(() => {
            if (this.isTerminal && this.ticketDetailsData) {
              if (this.ticketDetailsData.status.id !== '5') {
                fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
                  headers: {
                    Authorization: `Basic ${this.getTicketDetailsAuth}`,
                  },
                })
                  .then((response) => response.text())
                  .then((text) => this.setTicketDetailsData(JSON.parse(text)));
              }
            }
          }, 1000);
          if (this.isLoggedIn && !this.isTerminal) this.getLastTickets();
          this.setGameState(eventDetail);
          this.getResults();
          this.getStats();
          break;
        case 'StartCountdown':
          if (this.isLoggedIn && !this.isTerminal) {
            this.getLastTickets();
          }
          if (this.isStandAlone && !this.isGravityGateway) {
            this.getUserBalance();
          }
          if (this.isTerminal) {
            const countdownData = {
              delay: eventDetail.delay,
              sentTime: eventDetail.sentTime,
              time: Date.now(),
            };
            this.setCountdownData(countdownData);
          }
          break;
        case 'UI.Show': {
          this.setActiveComponent(event.detail.data.name[0]);
          break;
        }
        case 'Slave.Snooze': {
          this.setActiveComponent(null);
          break;
        }
        case 'TicketUpdate':
          this.getUserBonuses();
          if (!this.isTerminal) {
            this.getUserBonuses();
            if (eventDetail.id && eventDetail.status.id === '0') {
              let message = this.translations.general_ticket_confirmed;
              let status = 'positive';
              if (eventDetail.bonuses && eventDetail.bonuses.length) {
                message = this.translations.general_free_bet_confirmed_message;
                status = 'bonus';
              }
              notification.message = message;
              notification.status = status;

              // eslint-disable-next-line no-case-declarations
              const ticketsResolved = {
                action: 'Tickets.Resolved',
                data: {
                  accepted: true,
                  ticket: event.detail,
                },
              };
              // Send analytics message to parent frames
              if (this.isAndroidPlatform) {
                window.WebAppListener.sendMessage(JSON.stringify(ticketsResolved));
              }
              this.busService.sendMessage(ticketsResolved, true);
            }
            if (eventDetail.id && eventDetail.status.id === '1') {
              notification.message = this.translations.general_ticket_canceled;
              notification.status = 'neutral';
              this.ticketCanceled = true;
              this.canceledTicket = event.detail;
              this.getTicketsHistory();
            }
            if (eventDetail.code) {
              notification.message = eventDetail.message;
              notification.status = 'negative';
              if (this.ticket.length === 1 && this.isQuickpayEnabled) {
                this.clearBetslip();
              }
            } else {
              this.clearBetslip();
            }
            this.getLastTickets();
            store.dispatch('notifications/clearNotification')
              .then(() => {
                store.dispatch('notifications/setNotification', notification);
              });
            this.disableFreeBetButton(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('bettingDisable', false);
            this.clearCheckTimeout();
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
            if (this.isBetslipOpened) {
              store.dispatch('betslip/showBetslip');
            }
          }
          if (this.isTerminal) {
            const ticket = JSON.parse(JSON.stringify(event.detail));
            ticket.parsedResponse = ticket.bets;
            //
            ticket.ticketPin = event.detail.ticketPin;
            each(ticket.parsedResponse, (bet) => {
              const singleBet = {
                category: 'STANDARD',
                title: null,
                eventValue: null,
                value: null,
              };
              //  eslint-disable-next-line
              if (bet.type === 0) {
                singleBet.title = bet.value;
                singleBet.eventValue = '';
                // eslint-disable-next-line
                bet.bet = singleBet;
                // eslint-disable-next-line
                bet.eventValue = '';
              }
              //  }
              //  eslint-disable-next-line
              bet.displayName = bet.type === 0 ? 'STANDARD' : this.translations[this.getBetTypeLabel(bet.type)];
              if (bet.type === 1 || bet.type === 2 || bet.type === 3 || bet.type === 4) {
                const systemTypes = {
                  1: '6/7',
                  2: '6/8',
                  3: '6/9',
                  4: '6/10',
                };
                //  eslint-disable-next-line
                bet.displayName = `${this.translations.general_ticket_system} ${systemTypes[bet.type]}`;
              }
              //  eslint-disable-next-line
              bet.displayValue =  bet.type === 0 || bet.type === 2 || bet.type === 3 || bet.type === 4 || bet.type === 1 ? bet.value : this.translations[this.getBetNameLabel(bet)];
              if (bet.type === 5) {
                //  eslint-disable-next-line
                bet.displayValue = this.getBetNameLabel(bet);
              }
              if (bet.type === 10) {
                //  eslint-disable-next-line
                bet.displayValue = this.getBetNameLabel(bet);
              }
            });
            ticket.translation = this.translations.pages_company_help_virtual_games_lucky_six;
            if (event.detail.status.id === '0' && this.activeTerminalLayout === 'Terminal') {
              ticket.payinConfirmation = this.translations['print.payin_confirmation'];
              const additionalData = {
                clientPrintJobIdentifier: {
                  id: 'TicketPayIn',
                  uuid: ticket.requestUuid,
                },
              };
              this.busService.sendMessage({
                action: 'Peripherals.Print',
                enforceEvent: true,
                data: {
                  type: 'ticket',
                  productId: 'LuckySix',
                  data: ticket,
                  additionalData,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Add',
                  ticketData: ticket,
                  showMessage: true,
                },
              }, true);
              //  this.busService.sendMessage({
              //    action: 'Dialog.Show',
              //    data: {
              //      message: this.translations['ticket.add_success'],
              //      type: 'success',
              //      delay: 3000,
              //    },
              //  }, true);
              if (this.ticketToRebet) {
                this.setTicketToRebet(null);
              }
            }
            if (event.detail.code) {
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: event.detail.message,
                  type: 'warning',
                  delay: 3000,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Payout',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            }
            if (event.detail.status.id === '12') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Add',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
            }
            if (event.detail.status.id === '5') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Payout',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              if (this.userIsOperator) {
                this.busService.sendMessage({
                  action: 'Peripherals.Print',
                  enforceEvent: true,
                  data: {
                    layoutName: 'ticketLuckySixPayout',
                    productId: this.config.productAlias,
                    data: ticket,
                  },
                }, true);
              }
              if (!this.userIsOperator) {
                this.busService.sendMessage({
                  action: 'Dialog.Show',
                  data: {
                    message: this.translations['ticket.payout_success_to_balance']
                      .replace('{{amount}}', event.detail.payout.toFixed(2)),
                    type: 'success',
                    delay: 3000,
                  },
                }, true);
              }
              fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${ticket.id}.json`, {
                headers: {
                  Authorization: `Basic ${this.getTicketDetailsAuth}`,
                },
              })
                .then((response) => response.text())
                .then((text) => this.setTicketDetailsData(JSON.parse(text)));
            }
            if (event.detail.status.id === '1') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Cancel',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.cancel_success_to_balance']
                    .replace('{{amount}}', event.detail.payin.toFixed(2)),
                  type: 'success',
                  delay: 3000,
                },
              }, true);
              each(ticket.bets, (bet) => {
                const singleBet = {
                  category: 'STANDARD',
                  title: null,
                  eventValue: null,
                  value: null,
                };
                //  eslint-disable-next-line
                if (bet.type === 0) {
                  singleBet.title = bet.value;
                  singleBet.eventValue = '';
                  // eslint-disable-next-line
                  bet.bet = singleBet;
                  // eslint-disable-next-line
                  bet.eventValue = '';
                }
                //  }
                //  eslint-disable-next-line
                bet.typeValue = bet.type === 0 ? 'STANDARD' : this.translations[this.getBetTypeLabel(bet.type)];
              });
              this.setTicketDetailsData(ticket);
            }
            this.setIsPayinButtonDisabled(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.clearCheckTimeout();
            this.clearBetslip();
            store.dispatch('bettingDisable', false);
          }
          break;
        case 'Tickets.FetchHistory':
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_HISTORY_PATH}/${this.config.productId}.json?deviceUuid=${this.config.device.deviceUuid}&timeFrom=${event.detail.data.timeFrom}&timeTo=${event.detail.data.timeTo}&count=${event.detail.data.count}`, {
            headers: this.getTicketHistoryAuthHeaders,
          })
            .then((response) => response.text())
            .then((text) => this.setTicketHistoryTerminal(JSON.parse(text)));
          break;
        case 'Tickets.Checked':
          // eslint-disable-next-line
          let ticketPin = null;
          if (event.detail.data.ticket.ticketPin) {
            ticketPin = event.detail.data.ticket.ticketPin;
          }
          this.setLoaded(false);
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${event.detail.data.ticket.id}.json`, {
            headers: {
              Authorization: `Basic ${this.getTicketDetailsAuth}`,
            },
          })
            .then((response) => response.text())
            .then((text) => {
              const formated = JSON.parse(text);
              formated.ticketPin = ticketPin;
              this.setTicketDetailsData(formated);
            });
          break;
        case 'User.AuthorizationChanged':
          if (this.isTerminal) {
            if (event.detail.data.userType === 'OPERATOR') {
              this.setUserIsOperator(true);
            }
          }
          // eslint-disable-next-line no-case-declarations
          const authData = eventDetail.data.auth;
          if (authData) {
            const playerId = authData?.user?.id || null;
            this.updatePlayerChannel(playerId);
            this.authUser(authData);
          }
          if (this.isStandAlone && this.isGravityGateway) {
            this.getUserBalance();
          }
          if (!authData.user || !authData.user.logged) {
            this.clearSelection();
            this.clearBetslip();
          }
          break;
        case 'User.ProfileChanged':
          this.setUserBalance(eventDetail.data.profile.balance);
          break;
        case 'User.BalanceChanged':
          if (eventDetail.data && !isNil(eventDetail.data.balance)) {
            const { balance } = eventDetail.data;
            this.setUserBalance(balance);
          }
          if (this.isStandAlone && this.isGravityGateway) {
            this.getUserBalance();
          }
          break;
        case 'balanceUpdate': {
          // eslint-disable-next-line no-case-declarations
          const [balanceArray] = eventDetail;
          if (balanceArray.length && !isNil(balanceArray[0].balanceAmt)) {
            this.setUserBalance(balanceArray[0].balanceAmt);
          }
          break;
        }
        case 'Bonus':
          this.bonuses = eventDetail.rawData;
          this.toggleBonusModal(true);
          break;
        default:
          break;
      }
    });
    window.addEventListener('visibilitychange', () => {
      if ((this.isMobile || this.isTablet) && this.isLoggedIn) {
        this.getLastTickets();
      }
    });
    this.$root.$on('ReturnToLobby', () => {
      this.busService.sendMessage({
        action: 'UI.Show',
        data: {
          name: 'Lobby',
        },
      }, true);
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
    });
    eventBus.$on('UpdateTicketOnError', this.updateTicketWithRequestUuid);
    eventBus.$on('LoginRequired', () => {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('User.LoginRequired');
      }
      this.busService.sendMessage({
        type: 'loginRequired',
        action: 'User.LoginRequired',
      }, true);
      if (this.isAndroidPlatform) {
        // Playtech requires this on Android platform
        this.checkForRedirect();
      }
      integrator.sendMessage({
        type: 'loginRequired',
      });
    });
    eventBus.$on('TicketPayin', () => {
      if (this.isTerminal) {
        this.ticketPayinTerminal();
      } else {
        this.ticketPayin();
      }
    });
    eventBus.$on('TicketCancel', (ticket) => {
      this.busService.sendMessage({
        action: 'Tickets.Cancel',
        data: {
          ticket,
        },
      }, true);
    });
    eventBus.$on('PayoutTicket', (ticket) => {
      this.busService.sendMessage({
        action: 'Tickets.Payout',
        data: {
          ticket,
        },
      }, true);
    });
    eventBus.$on('LoadLastTickets', () => {
      if (!this.isTerminal) {
        this.getLastTickets();
      }
    });
    eventBus.$on('PrebetCleared', () => {
      this.clearSelection();
      // This is because we want to clear stake and freeBet
      if (!this.ticket.length) this.clearBetslip();
    });
    eventBus.$on('ClearBetslip', () => {
      this.clearSelection();
      this.clearBetslip();
    });
    eventBus.$on('PrintTicketCopy', (ticket) => {
      const mappedTicket = assign(ticket,
        { isCopy: true },
        { translation: this.translations.pages_company_help_virtual_games_lucky_six });
      mappedTicket.parsedResponse = ticket.bets;
      each(mappedTicket.parsedResponse, (bet) => {
        const singleBet = {
          category: 'STANDARD',
          title: null,
          eventValue: null,
          value: null,
        };
        //  eslint-disable-next-line
        if (bet.type === 0) {
          singleBet.title = bet.value;
          singleBet.eventValue = '';
          // eslint-disable-next-line
          bet.bet = singleBet;
          // eslint-disable-next-line
          bet.eventValue = '';
        }
        //  }
        //  eslint-disable-next-line
        bet.displayName = bet.type === 0 ? 'STANDARD' : this.translations[this.getBetTypeLabel(bet.type)];
        if (bet.type === 1 || bet.type === 2 || bet.type === 3 || bet.type === 4) {
          const systemTypes = {
            1: '6/7',
            2: '6/8',
            3: '6/9',
            4: '6/10',
          };
          //  eslint-disable-next-line
          bet.displayName = `${this.translations.general_ticket_system} ${systemTypes[bet.type]}`;
        }
        //  eslint-disable-next-line
        bet.displayValue =  bet.type === 0 || bet.type === 2 || bet.type === 3 || bet.type === 4 || bet.type === 1 ? bet.value : this.translations[this.getBetNameLabel(bet)];
        if (bet.type === 5) {
          //  eslint-disable-next-line
          bet.displayValue = this.getBetNameLabel(bet);
        }
        if (bet.type === 10) {
          //  eslint-disable-next-line
          bet.displayValue = this.getBetNameLabel(bet);
        }
      });
      this.busService.sendMessage({
        action: 'Peripherals.Print',
        enforceEvent: true,
        data: {
          type: 'ticket',
          productId: 'LuckySix',
          data: mappedTicket,
        },
      }, true);
    });

    if (this.config.platformConfig.clientType) {
      if (this.userProfile && this.userProfile?.profile?.id) {
        this.updatePlayerChannel(this.userProfile.profile.id);
        if (!this.isTerminal) {
          this.getLastTickets();
        }
      }
    }
  },
};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    outline-style: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
html, body {
  background-color: var(--bg-color-1);
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  -ms-overflow-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-width: none;
  //height: 100vh;
}

html::-webkit-scrollbar {
  display: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;

  &.dark {
    background-image: url('assets/L6_bg_dark.jpg');
  }

  &.light {
    background-image: url('assets/L6_bg_light.jpg');
  }
  &.hide-cursor {
    * {
      cursor: none !important;
    }
  }

  .cancel-ticket-wrapper-desktop {
    .cancel-ticket {
      .modal {
        color: var(--text-primary-1);
      }
    }
  }
  .cancel-ticket-confirm,
  .free-bet-modal {
    .modal {
      background-color: var(--card);

      .modal-header, .modal-body {
        color: var(--text-primary-1);
      }

      .modal-header {
        font-size: 16px;
      }
      .modal-body {
        font-size: 14px;
        .modal-actions {
          display: none;
        }
      }
      .modal-footer {
        padding-right: 43px;
      }
      .button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: var(--secondary);
        text-transform: uppercase;
        cursor: pointer;
        font-family: Roboto, 'sans-serif';
        padding: 31px 0 27px 0;
      }
    }
  }
  .free-bet-modal {
    .modal {
      max-height: 348px !important;
      .modal-header { display: none; }
      .modal-body {
        height: 348px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('assets/confeti.png');
        background-repeat: no-repeat;
        background-position: -11px 7px;
        position: relative;
        text-align: center;

        .body-message-box {
          position: absolute;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%);

          .title {
            font-size: 42px;
            font-weight: 700;
            color: #90B653;
            margin-bottom: 25px;
          }
          .subtitle { font-size: 18px; font-weight: 700; }
          .subtitle-2 { font-size: 14px; color: var(--text-primary-3); }
        }

        .action {
          position: absolute;
          bottom: 0;
          right: 16px;
        }
      }
      .modal-footer {
        display: none;
      }
    }
  }
}

@media (max-width: 640px) {
  .app {
    .cancel-ticket-confirm {
      background-color: var(--overlay-lock);
      .modal {
        background-color: var(--card);
        color: var(--text-primary-1);
        height: 200px !important;

        .modal-header {
          background-color: var(--card);
          padding: 24px 24px 30px 24px;
        }

        .modal-body {
          position: relative;
          padding: 0 24px;
          .modal-actions {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            padding-right: 43px;
          }
        }

        .modal-footer {
          display: none;
        }
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.1) {
  .ticket-wrapper {
    zoom: 0.9;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .ticket-wrapper {
    zoom: 0.8;
  }
}
</style>
