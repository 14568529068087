<template>
  <Help :help-page="helpPage" />
</template>

<script>
import { mapGetters } from 'vuex';
import { Help, gamesStatic } from '@nsftx/games-sdk-js';

export default {
  name: 'GameHelp',
  components: {
    Help,
  },
  data() {
    return {
      helpPage: '',
    };
  },
  computed: {
    ...mapGetters(['config']),
  },
  async mounted() {
    const { productName, locale, tenantId } = this.config;
    const theme = this.config?.ui?.config?.theme;
    this.helpPage = await gamesStatic.getHelp(productName, locale, tenantId, theme);
  },
};
</script>
