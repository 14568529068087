<template>
  <div class="results-wrapper">
    <div class="round-row"
         v-for="(round, key) in items"
         :key="key">
      <Accordion @change="selectRound($event, round)"
                 :isExpanded="selectedRound === round.eventId"
                 :title="roundLabel + ' ' + round.eventId">
        <div class="draw-sequence">
          <div class="title">
            <span>{{ translations.general_drawn_balls }}</span>
          </div>
          <div class="balls">
            <BingoGridButton
              v-for="(drawn, index) in round.balls.slice(0, 5)"
              :label="drawn.ball"
              variation="circle"
              :active="true"
              :key="index"
              :color="bingoColors[getBallColor(drawn.ball)]"
            />
          </div>
          <div class="balls">
            <BingoGridButton
              v-for="(drawn, index) in round.balls.slice(5, 35)"
              :label="drawn.ball"
              variation="circle"
              :active="true"
              :key="index"
              :color="bingoColors[getBallColor(drawn.ball)]"
            />
          </div>
        </div>
        <div class="draw-sequence"
             v-if="round.bonusBalls.length">
          <div class="title">
            <LocalizedLabel translation="generalBonusBalls" />
          </div>
          <div class="balls">
            <BingoGridButton
              variation="circle"
              v-for="(ball, key) in round.bonusBalls"
              :key="key"
              :label="ball"
              :active="true"
              :color="bingoColors[getBallColor(ball)]"
            />
          </div>
        </div>
        <div class="special-draw-info">
          <div class="draw-data"
               v-if="bets['6'].value">
            <span class="label">
              <LocalizedLabel translation="luckysixPreballsSum" />
            </span>
            <span class="value">
              {{round.preballsSumText}}
            </span>
          </div>
          <div class="draw-data"
               v-if="bets['11'].value">
            <span class="label">
              <LocalizedLabel translation="luckysixMorePreballs" />
            </span>
            <span class="value">
              {{round.preballsParityText}}
            </span>
          </div>
          <div class="draw-data">
            <span class="label">
              {{ translations.games_first_ball_color }}
            </span>
            <span class="value">
              {{ translations[`luckysix_color_${getFirstBallColor(round)}`]}}
            </span>
          </div>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import { each } from 'lodash';
import { Accordion, BingoGridButton } from '@nsftx/games-sdk-js';
import { Result } from '@/model/result';
import ballColor from '../utility/ballColor';

export default {
  name: 'Results',
  directives: {
    ClickOutside,
  },
  components: {
    Accordion,
    BingoGridButton,
  },
  data() {
    return {
      expanded: false,
      selectedRound: '',
      items: [],
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'resultsData',
      'colors',
      'appConfig',
      'config',
      'bingoColors',
      'bingoNumbers',
    ]),
    dropdownIcon() {
      return this.expanded ? 'icon-arrow-up-d' : 'icon-arrow-down-d';
    },
    roundLabel() {
      return this.translations.general_round;
    },
    bets() {
      return this.config.bets;
    },
  },
  methods: {
    selectRound(event, round) {
      this.selectedRound = round.eventId;
    },
    getBallColor(ball) {
      return ballColor.getColorId(ball);
    },
    getFirstBallColor(round) {
      return this.getBallColor(round.balls[0].ball);
    },
    updateResults() {
      let item = {};
      this.items = [];
      each(this.resultsData, (roundItem) => {
        item = new Result(roundItem, this.config);
        item.update();
        this.items.push(item);
      });
    },
  },
  mounted() {
    this.updateResults();
  },
  watch: {
    resultsData(results) {
      if (results.length) {
        this.updateResults();
      }
    },
  },
};
</script>

<style lang="scss">
  .results-wrapper {
    float: left;
    width: 100%;
    background: var(--event-details-bg, var(--card-bg));
    padding: 16px 8px;
    .draw-sequence {
      float: left;

      .title {
        float: left;
        width: 100%;
        color: var(--text-primary-2);
        font-size: 14px;
        text-align: left;
        margin-top: 16px;
        text-transform: uppercase;
      }

      .balls {
        float: left;
        width: 100%;
        margin-top: 8px;

        .button {
          float: left;
          border-width: 2px;
          line-height: 37px;
          font-size: 14px;
          margin: 0 5px 5px 0;
          text-align: center;
          cursor: default;
        }
      }
    }

    .special-draw-info {
      float: left;
      width: 100%;
      margin-top: 16px;

      .draw-data {
        float: left;
        width: 100%;
        text-align: left;
        margin-bottom: 16px;

        .label, .value {
          float: left;
          width: 100%;
          font-size: 14px;
        }

        .label {
          color: var(--text-primary-2);
        }

        .value {
          color: var(--text-primary-1);
          font-weight: 700;
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 960px) {
    .results-wrapper {
      float: left;
      width: 100%;

      .round-row {
        float: left;
        width: 100%;
        background-color: var(--tab-bg);
      }
    }
  }
</style>
